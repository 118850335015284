<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefanía Villada Chavarría                                   ###### -->
<!-- ###### @date: Septiembre 2024                                                 ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <!-- Menú para seleccionar el tipo de parametrización -->
    <v-card class="elevation-0">
      <v-tabs v-model="tipoCorreo" background-color="white" fixed-tabs height="28">
        <v-tabs-slider color="primary" class="slider"></v-tabs-slider>
        <v-tab> Aseguradoras </v-tab>
        <v-tab> Prestadores </v-tab>
        <v-tab> Servicios Farmacéuticos </v-tab>
      </v-tabs>
    </v-card>

    <v-tabs-items v-model="tipoCorreo" class="elevation-2">
      <!-- Correos de aseguradoras -->
      <v-tab-item>
        <v-card-text>
          <!-- Filtro para buscar la aseguradora -->
          <v-text-field class="me-2" v-model="buscar.aseguradora" label="Nombre"
            dense outlined hide-details color="primary">
          </v-text-field>

          <!-- Tabla de los correos de las aseguradoras -->
          <v-data-table class="elevation mt-4" :items="tabla.aseguradora.items" fixed-header hover
            :headers="tabla.aseguradora.headers" :loading="tabla.aseguradora.loading"
            :page.sync="tabla.aseguradora.page" :server-items-length="tabla.aseguradora.totalElements"
            :footer-props="tabla.aseguradora.footerProps" no-data-text="No se encontraron resultados"
            :items-per-page.sync="tabla.aseguradora.itemsPerPage" height="54vh">
            <!-- Columna de las acciones -->
            <template v-slot:[`item.acciones`]="{ item }">
              <!-- Opción editar una contraindicación -->
              <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'gray'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="item.eliminado === false && abrirDialogoEditar(item)">
                    <v-icon :color="item.eliminado === false ? 'orange' : 'gray'">border_color
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ item.eliminado === false ? 'Editar' : 'Registro inactivo' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-tab-item>

      <!-- Correos de prestadores -->
      <v-tab-item>
        <v-card-text>
          <!-- Filtro para buscar el prestador-->
          <v-text-field v-model="buscar.prestador" label="Nombre" 
            dense outlined hide-details  color="primary">
          </v-text-field>

          <!-- Tabla de los correos de los prestadores -->
          <v-data-table class="elevation mt-4" :items="tabla.prestador.items" fixed-header hover
            :headers="tabla.prestador.headers" :loading="tabla.prestador.loading"
            no-data-text="No se encontraron resultados" :page.sync="tabla.prestador.page"
            :server-items-length="tabla.prestador.totalElements" :footer-props="tabla.prestador.footerProps"
            :items-per-page.sync="tabla.prestador.itemsPerPage" height="54vh">
            <!-- Columna de las acciones -->
            <template v-slot:[`item.acciones`]="{ item }">
              <!-- Opción para editar una prestador -->
              <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'gray'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="item.eliminado === false && abrirDialogoEditar(item)">
                    <v-icon :color="item.eliminado === false ? 'orange' : 'gray'">border_color
                    </v-icon>
                  </v-btn>
                </template>
                <span class="text-center">{{ item.eliminado === false ? 'Editar' : 'Registro inactivo' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-tab-item>

      <!-- Correos de servicios farmacéuticos -->
      <v-tab-item>
        <v-card-text>
          <!-- Filtro para buscar la bodega-->
          <v-text-field v-model="buscar.bodega" label="Nombre" dense outlined
            hide-details color="primary">
          </v-text-field>

          <!-- Tabla de los correos de los servicios farmacéuticos -->
          <v-data-table class="elevation mt-4" :items="tabla.bodega.items" fixed-header hover
            :headers="tabla.bodega.headers" :loading="tabla.bodega.loading" :page.sync="tabla.bodega.page"
            :server-items-length="tabla.bodega.totalElements" :footer-props="tabla.bodega.footerProps"
            :items-per-page.sync="tabla.bodega.itemsPerPage" height="54vh"
            no-data-text="No se encontraron resultados">
            <!-- Columna de las acciones -->
            <template v-slot:[`item.acciones`]="{ item }">
              <!-- Opción para editar un correo de dispensación -->
              <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'gray'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="item.eliminado === false && abrirDialogoEditar(item)">
                    <v-icon :color="item.eliminado === false ? 'orange' : 'gray'">border_color
                    </v-icon>
                  </v-btn>
                </template>
                <span class="text-center">{{ item.eliminado === false ? 'Editar' : 'Registro inactivo' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>

    <!-- Dilogo para editar el correo de una aseguradora o un prestador -->
    <v-dialog v-model="dialogoCorreo" transition="dialog-bottom-transition" max-width="35rem" persistent>
      <v-card>
        <v-card-title class="encabezado"> Editar correo </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="agregarCorreo">
            <!-- Campo que alamacena la aseguradora o el prestador seleccionado -->
            <v-text-field v-model="agregarCorreo.entidad" readonly outlined dense
              :label="tipoCorreo === 0 ? 'Aseguradora' : 'Prestador'" color="primary">
            </v-text-field>

            <!-- Campo para ingresar el correo -->
            <v-text-field v-model="agregarCorreo.correo" outlined dense label="Correo" :rules="[rules.required]" 
              color="primary" @input="validarCorreo" :error-messages="errorCorreo"  @keypress="formatoCorreo" @paste="validarPegado">
            </v-text-field>
          </v-form>
          <div class="d-flex justify-end">
            <v-btn class="me-2" text color="error" @click="clear()">CERRAR</v-btn>
            <v-btn depressed :color="!formCorreo ? 'gray' : 'success'" @click="editarCorreo()" :disabled="!formCorreo">GUARDAR</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo editar los correos de los servicios farmacéuticos -->
    <v-dialog v-model="dialogoCorreoSF" transition="dialog-bottom-transition" max-width="35rem" persistent>
      <v-card>
        <v-card-title class="encabezado">Editar correo</v-card-title>
        <v-card-text class="pt-6">
          <!-- Campo que almacena la bodega seleccionada -->
          <v-text-field v-model="agregarCorreoSF.bodega" dense outlined label="Bodega" color="primary" readonly>
          </v-text-field>
          <v-form ref="correoSF">
            <!-- Campo para ingresar el correo -->
            <v-text-field id="text-field" v-model="agregarCorreoSF.correo" dense outlined label="Correo" color="primary"
              :rules="[rules.required]" @input="validarCorreo" :error-messages="error.correo"  @keypress="formatoCorreo" @paste="validarPegado">
            </v-text-field>

            <!-- Campo para ingresar el correo del director técnico -->
            <v-text-field v-model="agregarCorreoSF.correoDT" label="Correo director técnico" dense outlined color="primary" 
            :rules="[rules.required]" @input="validarCorreo" :error-messages="error.correoDT"  @keypress="formatoCorreo" @paste="validarPegado">
            </v-text-field>

            <!-- Campo para ingresar el correo del PAFI -->
            <v-text-field v-model="agregarCorreoSF.correoPafi" label="Correo PAFI" dense outlined color="primary" :rules="[rules.required]"
              @input="validarCorreo" :error-messages="error.correoPafi" @keypress="formatoCorreo" @paste="validarPegado">
            </v-text-field>
          </v-form>
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="clear()">CERRAR</v-btn>
            <v-btn depressed :color="!formCorreoSF ? 'gray' : 'success'" @click="editarCorreoSF()" :disabled="!formCorreoSF">GUARDAR</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";

export default {
  name: 'ParametrizacionCorreos',
  data() {
    return {
      tipoCorreo: 0,
      dialogoCorreo: false,
      dialogoCorreoSF: false,
      dialogoDetalles: false,
      correoSeleccionado: {},
      correoSFSeleccionado: {},
      buscar: {
        aseguradora: '',
        prestador: '',
        bodega: '',
      },
      tabla: {
        aseguradora: {
          loading: false,
          items: [],
          headers: [
            { text: "CÓDIGO", value: 'idAseguradora', width: "10%", sortable: false },
            { text: "NOMBRE", value: 'nombreAseguradora', width: "40%", sortable: false },
            { text: "CORREO", value: 'correo', width: "40%", sortable: false },
            { text: "ACCIONES", value: 'acciones', width: "10%", sortable: false, align: "center" },
          ],
          footerProps: {
            'items-per-page-options': [10, 20, 30],
            'items-per-page-text': 'Items por página:',
            'show-current-page': true,
            'show-first-last-page': true
          },
          itemsPerPage: 10,
          page: 1,
          totalElements: 0
        },
        prestador: {
          loading: false,
          items: [],
          headers: [
            { text: "CÓDIGO HABILITACIÓN", value: 'codigoHabilitacion', width: "10%", sortable: false },
            { text: "NOMBRE", value: 'nombrePrestador', width: "40%", sortable: false },
            { text: "CORREO", value: 'email', width: "40%", sortable: false },
            { text: "ACCIONES", value: 'acciones', width: "10%", sortable: false, align: "center" },
          ],
          footerProps: {
            'items-per-page-options': [10, 20, 30],
            'items-per-page-text': 'Items por página:',
            'show-current-page': true,
            'show-first-last-page': true
          },
          itemsPerPage: 10,
          page: 1,
          totalElements: 0
        },
        bodega: {
          loading: false,
          items: [],
          headers: [
            { text: "CÓDIGO", value: 'bodegasId.codigoBodega', width: "5%", sortable: false },
            { text: "NOMBRE", value: 'nombreBodega', width: "22.5%", sortable: false },
            { text: "CORREO", value: 'correo', width: "22.5%", sortable: false },
            { text: "CORREO DIRECTOR TÉCNICO", value: 'correoDT', width: "22.5%", sortable: false },
            { text: "CORREO PAFI", value: 'correoPafi', width: "22.5%", sortable: false },
            { text: "ACCIONES", value: 'acciones', align: 'center', width: "5%", sortable: false },
          ],
          footerProps: {
            'items-per-page-options': [10, 20, 30],
            'items-per-page-text': 'Items por página:',
            'show-current-page': true,
            'show-first-last-page': true
          },
          itemsPerPage: 10,
          page: 1,
          totalElements: 0
        },
      },
      agregarCorreo: {
        entidad: '',
        correo: '',
      },
      agregarCorreoSF: {
        bodega: '',
        correo: '',
        correoDT: '',
        correoPafi: '',
      },
      rules: {
        required: (v) => !!v || "Este campo es requerido."
      },
      errorCorreo: '',
      error: {
        correo: '',
        correoDT: '',
        correoPafi: '',
      }
    }
  },
  mounted() {
    this.listarCorreosAseguradoras();
  },
  computed: {
    ...mapState(["enterprise"]),
    formCorreo() {
      return this.agregarCorreo.entidad && this.agregarCorreo.correo && !this.errorCorreo;
    },
    formCorreoSF() {
      return this.agregarCorreoSF.bodega && this.agregarCorreoSF.correo && this.agregarCorreoSF.correoDT 
      && this.agregarCorreoSF.correoPafi && !this.error.correo && !this.error.correoDT && !this.error.correoPafi;
    },
  },
  watch: {
    'tabla.aseguradora.page': function () {
      this.listarCorreosAseguradoras();
    },
    'tabla.aseguradora.itemsPerPage': function () {
      this.tabla.aseguradora.page = 1;
      this.listarCorreosAseguradoras();
    },
    'buscar.aseguradora': function () {
      this.tabla.aseguradora.page = 1;
      this.listarCorreosAseguradoras();
    },
    'tabla.prestador.page': function () {
      this.listarCorreosPrestadores();
    },
    'tabla.prestador.itemsPerPage': function () {
      this.tabla.prestador.page = 1;
      this.listarCorreosPrestadores();
    },
    'buscar.prestador': function () {
      this.tabla.prestador.page = 1;
      this.listarCorreosPrestadores();
    },
    'tabla.bodega.page': function () {
      this.listarCorreosSF();
    },
    'tabla.bodega.itemsPerPage': function () {
      this.tabla.bodega.page = 1;
      this.listarCorreosSF();
    },
    'buscar.bodega': function () {
      this.tabla.bodega.page = 1;
      this.listarCorreosSF();
    },
    'tipoCorreo': function () {
      if (this.tipoCorreo === 1) {
        this.buscar.prestador = '';
        this.listarCorreosPrestadores();
      } else if (this.tipoCorreo === 2) {
        this.buscar.bodega = '';
        this.listarCorreosSF();
      } else {
        this.buscar.aseguradora = '';
        this.listarCorreosAseguradoras();
      }
    }
  },
  methods: {
    /**
     * Permite que solo los números ingresen en los campos.
     * @param event - El evento de entrada en los campos de texto.
     */
    soloNumeros(event) {
      const key = event.key;
      if (key.match(/\D/)) {
        event.preventDefault();
      }
    },
    formatoCorreo(event) {
      const key = event.key;
      const regex = /^[a-zA-Z0-9@._-]$/;

      if (!key.match(regex)) {
        event.preventDefault();
      }
    },
    validarPegado(event) {
    const contenidoPegado = (event.clipboardData || window.clipboardData).getData('text');
    const regex = /^[a-zA-Z0-9@._-]*$/;

    if (!contenidoPegado.match(regex)) {
      event.preventDefault();
    }
  },
    /**
     * Abre el diálogo para editar un correo.
     * @param item - El registro seleccionado. 
     */
    abrirDialogoEditar(item) {
      if (this.tipoCorreo !== 2) {
        this.correoSeleccionado = item;
        if (this.tipoCorreo === 0) {
          this.agregarCorreo.entidad = `${item.idAseguradora} - ${item.nombreAseguradora}`;
        } else {
          this.agregarCorreo.entidad = `${item.codigoHabilitacion} - ${item.nombrePrestador}`;
        }
        const correo = item.correo || item.email;
        this.agregarCorreo.correo = correo ? correo : null;
        setTimeout(() => {
          this.dialogoCorreo = true;
        }, 200);
      } else {
        this.correoSFSeleccionado = item;
        this.agregarCorreoSF = {...item};
        this.agregarCorreoSF.bodega = `${item.bodegasId.codigoBodega} - ${item.nombreBodega}`;
        setTimeout(() => {
          this.dialogoCorreoSF = true;
        }, 200);
      }
    },
    /** 
     * Ejecuta el método correspondiente para editar correos.
     */
    editarCorreo() {
      if (this.tipoCorreo === 0) {
        this.editarCorreoAseguradora();
      } else if (this.tipoCorreo === 1) {
        this.editarCorreoPrestador();
      }
    },
    /**
     * Ejecuta el método correspondiente para validar el correo ingresado.
     */
    validarCorreo() {
      if (this.tipoCorreo !== 2) {
        this.validacionCorreo();
      } else {
        this.validacionCorreoSF();
      }
    },

    // Métodos de los correos de las aseguradoras
    /**
     * Lista las combinaciones aseguradora por ciertos parámetros de búsqueda.
     */
    listarCorreosAseguradoras() {
      this.tabla.aseguradora.loading = true;
      this.$http.get(`/msa-setting/api/aseguradora/list`, {
        params: {
          idEmpresa: this.enterprise.code,
          nombreAseguradora: this.buscar.aseguradora,
          page: this.tabla.aseguradora.page - 1,
          size: this.tabla.aseguradora.itemsPerPage,
        }
      }).then((response) => {
        this.tabla.aseguradora.items = response.data.body.content;
        this.tabla.aseguradora.totalElements = response.data.body.totalElements;
        this.tabla.aseguradora.loading = false;
      }).catch((error) => {
        console.log(error);
        this.tabla.aseguradora.loading = false;
      })
    },
    /**
     * Agrega una nueva combinación contraindicación.
     */
     editarCorreoAseguradora() {
      const aseguradora = {
        empresa: {
          idEmpresa: this.enterprise.code
        },
        idAseguradora: this.correoSeleccionado.idAseguradora,
        correo: this.agregarCorreo.correo
      }
      this.$http.post(`msa-setting/api/aseguradora/add`, aseguradora)
      .then(() => {
        this.listarCorreosAseguradoras();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Método que valida el correo ingresado para aseguradora y prestador.
     */
    validacionCorreo() {
      const correo = this.agregarCorreo.correo;
      const regex = /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([^<>()[\],;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,})$/i;
      if (correo) {
        if (!correo.match(regex)) {
          this.errorCorreo = 'Correo inválido.';
        } else {
          this.errorCorreo = '';
        }
      } else {
        this.errorCorreo = '';
      }
    },

    // Métodos de los correos de los prestadores
    /**
     * Lista las combinaciones duplicidades por ciertos parámetros de búsqueda.
     */
    listarCorreosPrestadores() {
      this.tabla.prestador.loading = true;
      this.$http.get(`msa-setting/api/prestador/list`, {
        params: {
          idEmpresa: this.enterprise.code,
          nombrePrestador: this.buscar.prestador,
          page: this.tabla.prestador.page - 1,
          size: this.tabla.prestador.itemsPerPage,
        }
      }).then((response) => {
        this.tabla.prestador.items = response.data.body.content;
        this.tabla.prestador.totalElements = response.data.body.totalElements;
        this.tabla.prestador.loading = false;
      }).catch((error) => {
        console.log(error);
        this.tabla.prestador.loading = false;
      })
    },
    /**
     * Edita una combinación prestador existente.
     */
    editarCorreoPrestador() {
      const prestador = {
        idPrestador: this.correoSeleccionado.idPrestador,
        empresa: {
          idEmpresa: this.enterprise.code
        },
        email: this.agregarCorreo.correo,
      }
      this.$http.post(`msa-setting/api/prestador/add`, prestador)
      .then(() => {
        this.listarCorreosPrestadores();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },

    // Métodos de los correos de los servicios farmacéuticos
    /**
     * Lista las dispensacion por ciertos parámetros de búsqueda.
     */
    listarCorreosSF() {
      this.tabla.bodega.loading = true;
      this.$http.get(`msa-setting/api/bodega/listar`, {
        params: {
          page: this.tabla.bodega.page - 1,
          size: this.tabla.bodega.itemsPerPage,
          idEmpresa: this.enterprise.code,
          nombreBodega: this.buscar.bodega
        }
      }).then(response => {
        this.tabla.bodega.items = response.data.body.content;
        this.tabla.bodega.totalElements = response.data.body.totalElements;
        this.tabla.bodega.loading = false;
      }).catch((error) => {
        console.log(error);
        this.tabla.bodega.loading = false;
      })
    },
    /**
     * Edita una restricción existente.
     */
    editarCorreoSF() {
      const bodega = {
        bodegasId:{
          codigoBodega: this.correoSFSeleccionado.bodegasId.codigoBodega,
          idEmpresa: this.enterprise.code
        },
        correo: this.agregarCorreoSF.correo,
        correoDT: this.agregarCorreoSF.correoDT,
        correoPafi: this.agregarCorreoSF.correoPafi
      }
      this.$http.post(`msa-setting/api/bodega/add`, bodega)
      .then(() => {
        this.listarCorreosSF();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Método que valida los correos ingresados para los servicios farmacéuticos.
     */
    validacionCorreoSF() {
      const correo = this.agregarCorreoSF.correo;
      const correoDT = this.agregarCorreoSF.correoDT;
      const correoPafi = this.agregarCorreoSF.correoPafi;
      const regex = /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([^<>()[\],;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,})$/i;
      if (correo) {
        if (!correo.match(regex)) {
          this.error.correo = 'Correo inválido.';
        } else {
          this.error.correo = '';
        }
      } else {
        this.error.correo = '';
      }
      if (correoDT) {
        if (!correoDT.match(regex)) {
          this.error.correoDT = 'Correo inválido.';
        } else {
          this.error.correoDT = '';
        }
      } else {
        this.error.correoDT = '';
      }
      if (correoPafi) {
        if (!correoPafi.match(regex)) {
          this.error.correoPafi = 'Correo inválido.';
        } else {
          this.error.correoPafi = '';
        }
      } else {
        this.error.correoPafi = '';
      }
    },

    // Método para limpiar las variables.
    clear() {
      this.dialogoCorreo = false;
      this.dialogoCorreoSF = false;
      this.correoSeleccionado = {};
      this.correoSFSeleccionado = {};
      setTimeout(() => {
        if (this.$refs.agregarCorreo) {
          this.$refs.agregarCorreo.reset();
        }

        if (this.$refs.correoSF) {
          this.$refs.correoSF.reset();
        }
      }, 500)
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 86vh;
}

::v-deep .v-tabs-slider-wrapper {
  top: 85% !important;
  height: 1.99;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep #textarea {
  margin: 5px;
}

.campo {
  width: 50%;
}

.contenedor-lista {
  border: rgb(158 158 158) 1px solid;
  margin-bottom: 10px;
}

.lista {
  max-height: 260px;
  overflow-y: auto;
  min-height: 40px;
}

</style>